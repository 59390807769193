<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as LEAD_CNT_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ContactTableMixin from '../../../components/contact/mixins/ContactTableMixin'

export default {
  name: 'LeadContactTable',
  mixins: [ContactTableMixin],
  created () {
    if (!this.params.orgId) {
      delete (this.fields[5])
      delete (this.fields[7])
      delete (this.fields[9])
      delete (this.fields[10])
      delete (this.fields[11])
    }
  },
  computed: {
    ...mapGetters({
      selectAll: LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.GETTERS.selectAll,
      selectedList: LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.GETTERS.selectedList
    }),
    showDetailButton () {
      return hasPerm('organization_customercontact_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.MUTATIONS.setList,
      setSelectedList:
        LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.ACTIONS
    })

  }
}
</script>
