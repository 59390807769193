<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as LEAD_CONTACT_TYPES } from './store'
import OrganizationCommonFIltersMixin from '../../../components/organization/mixins/OrganizationCommonFiltersMixin'

export default {
  name: 'LeadContactCommonFilters',
  mixins: [OrganizationCommonFIltersMixin],
  computed: {
    ...mapGetters({
      filters: LEAD_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.GETTERS.filters
    })
  },
  methods: {
    ...mapActions({
      ...LEAD_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.ACTIONS
    })
  }
}
</script>
