<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as LEAD_CNT_TYPES } from './store'
import { ROUTES as LEAD_ORG_ROUTES } from '../../../organization/organization/lead-organization/router'
import { ROUTES as LEAD_CONTACT_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import LeadContactTable from './LeadContactTable'
import LeadContactCreate from './LeadContactCreate'
import LeadContactDetail from './LeadContactDetail'
import ContactListMixin from '../../../components/contact/mixins/ContactListMixin'
import LeadContactCommonFilters from './LeadContactCommonFilters'

export default {
  name: 'LeadContactList',
  mixins: [ContactListMixin],
  components: {
    'component-table': LeadContactTable,
    'common-filters': LeadContactCommonFilters,
    'component-edit': LeadContactCreate,
    'component-detail': LeadContactDetail
  },
  data () {
    return {
      LEAD_CONTACT_ROUTES,
      LEAD_ORG_ROUTES,
      title: this.$t('Contacts'),
      rolePerm: ['organization_customercontact_list'],
      actionEnablePermission: ['organization_customercontact_enable'],
      actionDisablePermission: ['organization_customercontact_disable'],
      orgType: this.$route.params.orgType
    }
  },
  computed: {
    ...mapGetters({
      ...LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.GETTERS
    }),
    showList () {
      return (
        this.$route.name === this.LEAD_CONTACT_ROUTES.LEAD_CONTACT_LIST ||
        this.$route.name === this.LEAD_ORG_ROUTES.LEAD_ORGANIZATION_DETAIL
      )
    },
    canAddInstance () {
      return hasPerm('organization_customercontact_create_with_organizations')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.MUTATIONS.setSelectedList,
      setSelectAll: LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...LEAD_CNT_TYPES.GENERIC.organization.customercontact.LIST.ACTIONS
    }),
    openDetailForm (cntId) {
      this.$emit('show-contact-detail', true)
      if (this.orgId) {
        this.$router.push({
          name: LEAD_ORG_ROUTES.LEAD_ORGANIZATION_CONTACT_DETAIL, params: { orgId: this.orgId, cntId: cntId }
        })
      } else {
        this.$router.push({
          name: LEAD_CONTACT_ROUTES.LEAD_CONTACT_DETAIL, params: { cntId: cntId }
        })
      }
    }
  }
}
</script>
